import React from 'react';
import { Section } from '../Section';
import { SectionTitle } from '../Typography';
import { Founder } from './index';
import { TwoColumns } from '../Section/index';
import sizes from 'src/style/sizes';
import styled from 'src/style';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

export const YellowElement = styled.p({ color: colors.yellow, display: 'inline' });

const Founders = React.memo(({ portraitXavier, portraitFarid, id, theme }) => {
  return (
    <Section theme={theme ? theme : 'light'} id={id}>
      <SectionTitle
        isCenter
        css={{
          paddingBottom: 90,
          display: 'inline',
          [mediaQueries.phoneOnly]: {
            paddingBottom: 60,
          },
        }}
      >
        Des formations créées par des développeurs{' '}
        <br
          css={{
            display: 'none',
            [mediaQueries.tabletLandscapeUp]: {
              display: 'block',
            },
          }}
        />
        pour les <YellowElement>(</YellowElement>futurs
        <YellowElement>) </YellowElement> développeurs
      </SectionTitle>

      <TwoColumns
        sizeLeft={sizes.columnWidth.medium}
        left={
          <Founder
            firstname="Xavier"
            lastname="Colombel"
            position="CEO"
            alt="Le Reacteur Fondateur : Xavier Colombel"
            image={portraitXavier}
            /* medium="https://medium.com/@XavierColombel" */
            github="https://github.com/XavierColombel"
            linkedin="https://fr.linkedin.com/in/xcolombel"
          />
        }
        sizeRight={sizes.columnWidth.medium}
        right={
          <Founder
            firstname="Farid"
            lastname="Safi"
            position="CTO"
            alt="Le Reacteur Fondateur : Farid Safi"
            image={portraitFarid}
            twitter="https://twitter.com/FaridSafi"
            github="https://github.com/FaridSafi"
            linkedin="https://fr.linkedin.com/in/faridsafi"
          />
        }
      />
    </Section>
  );
});

export default Founders;

/* export const query = graphql`
  query {
    farid: file(relativePath: { eq: "PortraitFarid.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    xavier: file(relativePath: { eq: "PortraitXavier.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
 */
