import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Avatar } from '../Avatars/index';
import { Description } from '../DescriptionBlock/index';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from '../../utils/MediaQueries';
import { SubtitleDescription, Sentence } from '../Typography';
import colors from 'src/style/colors';

const FounderAvatar = () => {
  const mqs = useMediaQueries();
  const data = useStaticQuery(graphql`
    query {
      farid: file(relativePath: { eq: "2023_Farid.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
    }
  `);
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        justifyContent: 'center',
        [mediaQueries.tabletLandscapeUp]: {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: 0,
        },
      }}
    >
      <ul>
        <Avatar
          name={data.farid.childImageSharp.gatsbyImageData}
          big={mqs.tabletLandscapeUp ? true : false}
          alt="photographie de Farid Safi, un des fondateurs du Reacteur"
        />
      </ul>

      <div css={{ height: 20 }} />

      <div
        css={{
          paddingLeft: 18,
          [mediaQueries.tabletPortraitUp]: {
            paddingLeft: 25,
          },
          [mediaQueries.tabletLandscapeUp]: {
            paddingLeft: 0,
          },
        }}
      >
        {mqs.tabletLandscapeUp ? (
          <Description title="Farid Safi" subtitle="CTO, Le Reacteur" />
        ) : (
          <div css={{ paddingLeft: 10 }}>
            <SubtitleDescription>Farid Safi</SubtitleDescription>
            <div css={{ display: 'flex', flexDirection: 'row' }}>
              <div
                css={{
                  width: 26,
                  height: 8,
                  backgroundColor: colors.yellow,
                  marginRight: '10px',
                  marginTop: 9,
                  borderRadius: 50,
                }}
              />
              <Sentence css={{ fontWeight: 'bold' }}>CTO, Le Reacteur</Sentence>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FounderAvatar;
