import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
/* --- import Components ----*/
import { TitleDescription, Paragraph, SubtitleDescription } from '../Typography';
import { Header, ContentWrapper, PositionWrapper, ButtonSocialMedia } from './elements';
import { Medium, Twitter, Github, Linkedin } from 'src/components/Icons/Social Media';
/* --- import Style ----*/
import { Line } from '../../style/lines';

/**
 * @type React.FunctionComponent<{ firstname: string; lastname: string; position: string; image: string, medium?: string, twitter?: string, linkedin?: string, github?:string }>
 */

const Founder = ({
  firstname,
  lastname,
  position,
  image,
  alt,
  medium,
  twitter,
  linkedin,
  github,
}) => {
  const bioFarid = () => {
    return (
      <>
        Farid est le premier formateur React Native en France, auteur de composants React Native
        open source utilisés par plusieurs dizaines de milliers de développeurs. Il était
        développeur C et PHP, avant de se spécialiser dans le JavaScript en 2006, et en est devenu
        expert au fil de ses expériences. Il a été directeur technique et consultant pour des grands
        groupes et des startups.
      </>
    );
  };

  const bioXavier = () => {
    return (
      <>
        Xavier a découvert la programmation en 1997, avec l'arrivée d'Internet en France. En
        l'absence d'école spécialisée, il s'est auto-formé à de nombreux langages de programmation,
        aux images de synthèse et à l'électronique (objets connectés). Avec Le Reacteur, il a à cœur
        de partager sa passion du code (et de JavaScript) et l’impact qu’il peut avoir sur nos vies,
        notre travail et notre façon de penser.
      </>
    );
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        <GatsbyImage
          image={image}
          alt={alt}
          style={{
            borderRadius: 5,
            marginRight: 25,
            height: 140,
            width: 140,
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
          }}
        />
        <ContentWrapper>
          <Header>
            <TitleDescription css={{ paddingBottom: 0 }}>
              {firstname}
              <span>&nbsp;</span>
              <br />
              {lastname}
            </TitleDescription>
          </Header>

          <PositionWrapper>
            <Line />
            <SubtitleDescription>{position}</SubtitleDescription>
          </PositionWrapper>
        </ContentWrapper>
      </div>
      <Paragraph>{firstname === 'Farid' ? bioFarid() : bioXavier()}</Paragraph>
      <div css={{ display: 'flex', flexDirection: 'row' }}>
        {medium && (
          <ButtonSocialMedia>
            <a href={medium} target="_blank" rel="noopener noreferrer" aria-label="medium">
              <Medium />
            </a>
          </ButtonSocialMedia>
        )}

        {twitter && (
          <ButtonSocialMedia>
            <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="twitter">
              <Twitter />
            </a>
          </ButtonSocialMedia>
        )}
        {github && (
          <ButtonSocialMedia>
            <a href={github} target="_blank" rel="noopener noreferrer" aria-label="github">
              <Github />
            </a>
          </ButtonSocialMedia>
        )}
        {linkedin && (
          <ButtonSocialMedia>
            <a href={linkedin} target="_blank" rel="noopener noreferrer" aria-label="linkedin">
              <Linkedin />
            </a>
          </ButtonSocialMedia>
        )}
      </div>
    </div>
  );
};

export default Founder;
